import Gumshoe from '../info/gumshoe.polyfills'
import SmoothScroll from '../info/smooth-scroll.polyfills'

smoothScroll = null
gumshoe = null
timer = null

# ScrollHandler: Add scrolled-class to body after scrollOffset. Used for logo animation.
scrollHandler = ->
  document.documentElement.style.setProperty('--banner-scroll-offset', "#{window.scrollY*0.5}px")
  window.cancelAnimationFrame(timeout) if timeout
  timeout = window.requestAnimationFrame(checkScrolled)

scrollOffset = 20
checkScrolled = ->
  if window.scrollY > scrollOffset
    document.body.classList.add('scrolled')
  else
    document.body.classList.remove('scrolled')


# END ScrollHandler

init = ->
  if (navbar = document.querySelector('#navbar'))
    offsetToNavbar = 300
    smoothScroll = new SmoothScroll 'a[href*="#"]',
                                    {
                                      speed           : 100
                                      speedAsDuration : true
                                      clip            : true
                                      offset          : offsetToNavbar
                                    }

    gumshoe = new Gumshoe '#navbar a',
                          {
                            reflow : true,
                            offset : ->
                              navBounds = navbar.getBoundingClientRect()
                              navBounds.top + navBounds.height + offsetToNavbar
                          }

  window.addEventListener('scroll', scrollHandler, false)

destroy = ->
  gumshoe?.destroy()
  smoothScroll?.destroy()

  gumshoe = null
  smoothScroll = null
  timer = null

  window.removeEventListener('scroll', scrollHandler, false)

document.addEventListener("turbolinks:load", init)
document.addEventListener("turbolinks:before-cache", destroy)
