require("@rails/ujs").start()
require("turbolinks").start()
import "bootstrap";
import '../info/styles/application.scss';
import '../info/scroll-and-spy';

// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// import {  faExternalLinkAlt, faBars } from '@fortawesome/free-solid-svg-icons'
// library.add(faExternalLinkAlt, faBars)
// dom.watch()
